import { cva } from "class-variance-authority"

export const buttonGroupRootVariants = cva(
  `
    isolate
    inline-flex
    items-center
    justify-center
    rounded
    text-sm
    font-medium
    ring-offset-background
    focus-visible:outline-none
    focus-visible:ring-2
    focus-visible:ring-offset-2 
    data-[orientation='vertical']:flex-col
    data-[disabled='true']:opacity-80
    `,
  {
    variants: {
      variant: {
        default: `
          divide-general-default
          data-[orientation='horizontal']:divide-x
          data-[orientation='vertical']:divide-y 
          data-[orientation='horizontal']:rtl:divide-x-reverse
          `,
        separated: "gap-2",
      },
      v2: {
        true: "divide-general-neutral-02",
      },
    },
  }
)

export const buttonGroupItemVariants = cva(
  `
  inline-flex
  h-8
  items-center
  justify-center
  bg-button-group-deselected
  outline-none
  ring-inset
  ring-white
  transition-colors
  font-small-400
  hover:bg-button-default-hover
  focus-visible:z-10
  focus-visible:border
  focus-visible:border-black
  focus-visible:ring-2
  disabled:cursor-not-allowed
  disabled:hover:bg-none
  aria-pressed:bg-button-group-selected
  aria-pressed:text-general-static-white-text
  aria-pressed:font-small-600
  aria-pressed:hover:bg-button-group-selected
  aria-pressed:hover:bg-none
  dark:focus-visible:border-none`,
  {
    variants: {
      variant: {
        default: `
          w-24
          text-button-inactive`,
        separated: "w-[8.5rem] rounded-md text-general-primary-text",
      },
      v2: {
        true: "hover:bg-button-primary-hovered disabled:hover:bg-button-group-deselected aria-pressed:text-icon-general-inverted",
      },
    },
  }
)
export type ButtonGroupVariants = typeof buttonGroupRootVariants
