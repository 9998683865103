import { DocumentMap, SingleResponse } from "@tastyworks/tastyworks-api"
import { get, readable, Readable } from "svelte/store"
import { customer } from "/@/util/customer"
import { twApiClient } from "/@lib/tastyworks-rest"

// NOTE: The regulatory domain needs to be uppercase.
//  The backend already stores the customer's regulatoryDomain uppercase.
export const documents: Readable<Promise<SingleResponse<DocumentMap>>> =
  readable(null, (set) =>
    set(
      twApiClient.generalDocumentService.indexBySlug(
        get(customer).regulatoryDomain
      )
    )
  )
