// type Enum | `${Enum}` === Enum | "val1" | ... | "valN"
export enum Directions {
  LTR = "ltr",
  RTL = "rtl",
}
export type Direction = Directions | `${Directions}`

export enum Orientations {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}
export type Orientation = Orientations | `${Orientations}`

export enum States {
  DISABLED = "disabled",
  ENABLED = "enabled",
  ACTIVE = "active",
  INACTIVE = "inactive",
}
export type State = States | `${States}`

export enum Activations {
  FOCUS = "focus",
  CLICK = "click",
}
export type Activation = Activations | `${Activations}`
