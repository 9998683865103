<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="biotech" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<path d="M12.18 7.017c0 0-0-0 0 0l3.522 0.944c0.164 0.044 0.293 0.172 0.337 0.336l0.945 3.518c0.265 1.003-0.018 2.083-0.759 2.823l-1.579 1.579c-0.738 0.738-1.818 1.033-2.826 0.758-0-0-0.001-0-0.001-0l-3.514-0.944c-0.164-0.044-0.292-0.172-0.336-0.336l-0.945-3.518c-0.265-1.003 0.018-2.083 0.758-2.823 0-0-0 0 0 0l1.572-1.58c0.738-0.738 1.81-1.033 2.826-0.758zM10.027 8.448c-0 0 0-0 0 0l-1.571 1.579c-0.498 0.498-0.69 1.227-0.511 1.906 0 0-0-0 0 0l0.873 3.25 3.253 0.874c0.674 0.184 1.403-0.012 1.903-0.512l1.579-1.579c0.498-0.498 0.69-1.227 0.511-1.906-0-0-0-0.001-0-0.001l-0.873-3.249-3.26-0.874c-0.682-0.185-1.404 0.012-1.904 0.512z"></path>
<path d="M15.662 2.139c0.186 0.186 0.186 0.487 0 0.673-0.538 0.538-0.746 1.322-0.551 2.056 0 0 0 0 0 0l0.849 3.17 3.17 0.849c0 0 0 0 0 0 0.734 0.195 1.519-0.014 2.057-0.551 0.186-0.186 0.488-0.186 0.673 0s0.186 0.487 0 0.673c-0.78 0.78-1.915 1.079-2.975 0.798l-0.001-0-3.436-0.921c-0.164-0.044-0.293-0.172-0.337-0.337l-0.921-3.438c-0.281-1.060 0.018-2.195 0.798-2.975 0.186-0.186 0.487-0.186 0.673 0z"></path>
<path d="M2.139 14.989c0.78-0.78 1.915-1.080 2.975-0.798l0.001 0 3.437 0.921c0.164 0.044 0.293 0.172 0.337 0.337l0.921 3.438c0.281 1.060-0.018 2.195-0.798 2.975-0.186 0.186-0.487 0.186-0.673 0s-0.186-0.487 0-0.673c0.538-0.538 0.746-1.323 0.551-2.057-0-0 0 0 0 0l-0.849-3.17-3.17-0.849c-0 0 0 0 0 0-0.734-0.195-1.519 0.014-2.057 0.551-0.186 0.186-0.487 0.186-0.673 0s-0.186-0.488 0-0.673z"></path>
<path d="M8.568 8.568c0.186-0.186 0.487-0.186 0.673 0l4.857 4.857c0.186 0.186 0.186 0.487 0 0.673s-0.487 0.186-0.673 0l-4.857-4.857c-0.186-0.186-0.186-0.487 0-0.673z"></path>
<path d="M9.917 7.385c0.186-0.186 0.488-0.186 0.673 0l4.778 4.778c0.186 0.186 0.186 0.487 0 0.673s-0.487 0.186-0.673 0l-4.778-4.778c-0.186-0.186-0.186-0.487 0-0.673z"></path>
<path d="M7.385 9.917c0.186-0.186 0.487-0.186 0.673 0l4.778 4.778c0.186 0.186 0.186 0.487 0 0.673s-0.487 0.186-0.674 0l-4.778-4.778c-0.186-0.186-0.186-0.487 0-0.673z"></path>
<path d="M5.631 16.362c0.186-0.186 0.487-0.186 0.673-0.001l3.429 3.421c0.186 0.186 0.187 0.487 0.001 0.674s-0.487 0.186-0.673 0.001l-3.429-3.421c-0.186-0.186-0.187-0.487-0.001-0.673z"></path>
<path d="M16.362 5.632c0.186-0.186 0.488-0.186 0.673 0l3.429 3.429c0.186 0.186 0.186 0.487 0 0.673s-0.488 0.186-0.673 0l-3.429-3.429c-0.186-0.186-0.186-0.487 0-0.673z"></path>
</svg>