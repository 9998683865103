<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="reit" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<path d="M2.484 9.824c0-0.276 0.224-0.5 0.5-0.5h13.725c0.276 0 0.5 0.224 0.5 0.5v8.176c0 0.276-0.224 0.5-0.5 0.5h-13.725c-0.276 0-0.5-0.224-0.5-0.5v-8.176zM3.484 10.324v7.176h12.725v-7.176h-12.725z"></path>
<path d="M16.208 13.279c0-0.276 0.224-0.5 0.5-0.5h4.575c0.276 0 0.5 0.224 0.5 0.5v4.721c0 0.276-0.224 0.5-0.5 0.5h-4.575c-0.276 0-0.5-0.224-0.5-0.5v-4.721zM17.208 13.779v3.721h3.575v-3.721h-3.575z"></path>
<path d="M17.39 14.9c0-0.276 0.224-0.5 0.5-0.5h2.226c0.276 0 0.5 0.224 0.5 0.5v3.1c0 0.276-0.224 0.5-0.5 0.5h-2.226c-0.276 0-0.5-0.224-0.5-0.5v-3.1zM18.39 15.4v2.1h1.227v-2.1h-1.227z"></path>
<path d="M9.627 5.551c0.138-0.067 0.3-0.067 0.438-0l7.854 3.824c0.209 0.102 0.321 0.335 0.268 0.562s-0.254 0.388-0.487 0.388h-15.7c-0.233 0-0.435-0.161-0.487-0.388s0.059-0.46 0.268-0.562l7.846-3.824zM9.846 6.556l-5.679 2.768h11.364l-5.685-2.768z"></path>
<path d="M8.446 13.216c0-0.276 0.224-0.5 0.5-0.5h1.807c0.276 0 0.5 0.224 0.5 0.5v3.376c0 0.276-0.224 0.5-0.5 0.5h-1.807c-0.276 0-0.5-0.224-0.5-0.5v-3.376zM9.446 13.716v2.376h0.807v-2.376h-0.807z"></path>
<path d="M7.89 16.591c0-0.276 0.224-0.5 0.5-0.5h2.92c0.276 0 0.5 0.224 0.5 0.5v1.409c0 0.276-0.224 0.5-0.5 0.5h-2.92c-0.276 0-0.5-0.224-0.5-0.5v-1.409zM8.89 17.091v0.408h1.92v-0.408h-1.92z"></path>
<path d="M2.484 16.591c0-0.276 0.224-0.5 0.5-0.5h13.725c0.276 0 0.5 0.224 0.5 0.5v1.409c0 0.276-0.224 0.5-0.5 0.5h-13.725c-0.276 0-0.5-0.224-0.5-0.5v-1.409zM3.484 17.091v0.408h12.725v-0.408h-12.725z"></path>
<path d="M4.375 11.571c0-0.276 0.224-0.5 0.5-0.5h1.815c0.276 0 0.5 0.224 0.5 0.5v2.557c0 0.276-0.224 0.5-0.5 0.5h-1.815c-0.276 0-0.5-0.224-0.5-0.5v-2.557zM5.375 12.071v1.557h0.815v-1.557h-0.815z"></path>
<path d="M12.51 11.571c0-0.276 0.224-0.5 0.5-0.5h1.815c0.276 0 0.5 0.224 0.5 0.5v2.557c0 0.276-0.224 0.5-0.5 0.5h-1.815c-0.276 0-0.5-0.224-0.5-0.5v-2.557zM13.51 12.071v1.557h0.815v-1.557h-0.815z"></path>
<path d="M16.431 10.667c0.139-0.093 0.315-0.11 0.469-0.046l5.292 2.195c0.22 0.091 0.345 0.326 0.299 0.559s-0.252 0.402-0.49 0.402h-5.292c-0.276 0-0.5-0.224-0.5-0.5v-2.195c0-0.167 0.084-0.323 0.222-0.416zM17.208 11.832v0.947h2.282l-2.282-0.947z"></path>
<path d="M12.51 6.197c0-0.276 0.224-0.5 0.5-0.5h1.38c0.276 0 0.5 0.224 0.5 0.5v2.014c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5v-1.514h-0.38v0.846c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5v-1.346z"></path>
</svg>