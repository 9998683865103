import {
  Directions,
  Orientations,
  type Direction,
  type Orientation,
} from "../constants"

// Ref: https://www.w3.org/TR/uievents-key/#named-key-attribute-values
export enum Keys {
  Space = " ",
  Enter = "Enter",
  Escape = "Escape",
  Backspace = "Backspace",
  Delete = "Delete",

  ArrowLeft = "ArrowLeft",
  ArrowUp = "ArrowUp",
  ArrowRight = "ArrowRight",
  ArrowDown = "ArrowDown",

  Home = "Home",
  End = "End",

  PageUp = "PageUp",
  PageDown = "PageDown",

  Tab = "Tab",
}

const SCROLL_KEYS = [
  Keys.ArrowUp,
  Keys.ArrowDown,
  Keys.ArrowLeft,
  Keys.ArrowRight,
  Keys.Home,
  Keys.End,
  Keys.PageUp,
  Keys.PageDown,
]

export function isScrollKey(key: string) {
  return SCROLL_KEYS.includes(key as Keys)
}

export function nextKey(
  orientation: Orientation = Orientations.HORIZONTAL,
  dir: Direction = Directions.LTR
) {
  return orientation === Orientations.VERTICAL
    ? Keys.ArrowDown
    : dir === Directions.RTL
    ? Keys.ArrowLeft
    : Keys.ArrowRight
}

export function prevKey(
  orientation: Orientation = Orientations.HORIZONTAL,
  dir: Direction = Directions.LTR
) {
  return orientation === Orientations.VERTICAL
    ? Keys.ArrowUp
    : dir === Directions.RTL
    ? Keys.ArrowRight
    : Keys.ArrowLeft
}
