<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="loginBiometricsFingerprint" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<path d="M2.4 12c0-6.302 4.274-11.6 9.771-11.6 1.791 0 3.468 0.575 4.904 1.563 0.273 0.188 0.342 0.562 0.154 0.835s-0.562 0.342-0.834 0.154c-1.252-0.863-2.695-1.352-4.223-1.352-4.639 0-8.571 4.547-8.571 10.4v11c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-11z"></path>
<path d="M18.455 3.966c0.26-0.205 0.638-0.16 0.843 0.1 1.638 2.081 2.635 4.881 2.635 7.934v11c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-11c0-2.795-0.914-5.331-2.378-7.191-0.205-0.26-0.16-0.638 0.1-0.843z"></path>
<path d="M5.696 14.874c0.331 0 0.6 0.269 0.6 0.6v7.526c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-7.526c0-0.331 0.269-0.6 0.6-0.6z"></path>
<path d="M5.087 11.523c0-4.515 3.066-8.364 7.075-8.364s7.075 3.85 7.075 8.364v3.768c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-3.768c0-4.065-2.724-7.164-5.875-7.164s-5.875 3.099-5.875 7.164v0.981c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-0.981z"></path>
<path d="M18.637 17.358c0.331 0 0.6 0.269 0.6 0.6v5.042c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-5.042c0-0.331 0.269-0.6 0.6-0.6z"></path>
<path d="M9.072 8.276c0.3 0.141 0.428 0.499 0.287 0.799-0.277 0.588-0.439 1.271-0.439 2.008v11.917c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-11.917c0-0.913 0.2-1.77 0.553-2.52 0.141-0.3 0.499-0.428 0.799-0.287z"></path>
<path d="M11.571 6.473c0-0.331 0.269-0.6 0.6-0.6 2.548 0 4.442 2.438 4.442 5.211v11.917c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-11.917c0-2.324-1.551-4.011-3.242-4.011-0.331 0-0.6-0.269-0.6-0.6z"></path>
<path d="M10.146 11.12c0-1.167 0.81-2.314 2.025-2.314s2.025 1.147 2.025 2.314c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6c0-0.721-0.471-1.114-0.825-1.114s-0.825 0.393-0.825 1.114v11.88c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-11.88z"></path>
<path d="M13.596 13.252c0.331 0 0.6 0.269 0.6 0.6v9.148c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-9.148c0-0.331 0.269-0.6 0.6-0.6z"></path>
</svg>