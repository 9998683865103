<script context="module" lang="ts">
  type Variant = VariantProps<ButtonGroupVariants>["variant"]

  type SingleButtonRootProps = {
    type?: "single"
    value?: any
  }

  type MultipleButtonRootProps = {
    type: "multiple"
    value?: string[] | number[] | null
  }

  export type ButtonGroupRootProps = BaseProps<"div"> &
    (SingleButtonRootProps | MultipleButtonRootProps) & {
      name?: string
      disabled?: boolean
      deselect?: boolean
      loop?: boolean
      rovingFocus?: boolean
      variant?: Variant
      dir?: Direction
      orientation?: Orientation
    }

  export type ButtonGroupContext = {
    readonly type: "single" | "multiple"
    readonly orientation: Orientation
    readonly dir: Direction
    readonly loop: boolean
    readonly rovingFocus: boolean
    readonly disabled: ButtonGroupRootProps["disabled"]
    readonly deselect: boolean
    readonly name: string | undefined
    readonly variant: Variant
    value: ButtonGroupRootProps["value"]
  }

  const defaults = {
    type: "single",
    name: undefined,
    disabled: false,
    deselect: false,
    loop: true,
    rovingFocus: true,
    variant: "default",
    dir: Directions.LTR,
    orientation: Orientations.HORIZONTAL,
    value: null,
  } satisfies Defaults<ButtonGroupContext>

  const { setContext, getContext } =
    reactiveContext<ButtonGroupContext>(defaults)
  export const getButtonGroupRootContext = getContext
  const itemCollection = collectionContext()
  export const getButtonGroupItemCollection = itemCollection.getContext
</script>

<script lang="ts">
  import {
    Directions,
    Orientations,
    type Direction,
    type Orientation,
  } from "#lib/internal/constants"
  import {
    collectionContext,
    getUiclContext,
    reactiveContext,
    useActions,
    type Defaults,
  } from "#lib/internal/helpers"
  import { enableGroupKeyboardNavigation } from "#lib/internal/helpers/group"
  import type { BaseProps } from "#lib/internal/types"
  import type { VariantProps } from "class-variance-authority"
  import { buttonGroupRootVariants, type ButtonGroupVariants } from "./variants"

  type $$Props = ButtonGroupRootProps
  type $$Slots = { default: object }
  type DefaultedProps = Required<$$Props>

  let className: $$Props["class"] = ""
  export { className as class }
  export let type: DefaultedProps["type"] = defaults.type
  export let name: $$Props["name"] = defaults.name
  export let disabled: DefaultedProps["disabled"] = defaults.disabled
  export let deselect: DefaultedProps["deselect"] = type === "multiple"
  export let loop: DefaultedProps["loop"] = defaults.loop
  export let rovingFocus: DefaultedProps["rovingFocus"] = defaults.rovingFocus
  export let variant: DefaultedProps["variant"] = defaults.variant
  export let dir: DefaultedProps["dir"] = defaults.dir
  export let orientation: DefaultedProps["orientation"] = defaults.orientation
  export let role: DefaultedProps["role"] = "group"
  export let value: DefaultedProps["value"] = defaults.value

  const ctx = setContext({ value: (v) => (value = v) })

  $: $ctx = {
    variant,
    value,
    name,
    type,
    orientation,
    dir,
    disabled,
    deselect,
    loop,
    rovingFocus,
  }

  const itemStore = itemCollection.setContext()
  const listeners = new Map()
  $: enableGroupKeyboardNavigation(itemStore, listeners, orientation, dir)

  const v2 = !!getUiclContext()?.v2
</script>

<div
  {role}
  {dir}
  {...$$restProps}
  data-disabled={disabled}
  data-orientation={orientation}
  class={buttonGroupRootVariants({ variant, className, v2 })}
  use:useActions={$$restProps.use}
>
  <slot />
</div>
