<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="futuresOil" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<path d="M5.25 3c0-0.414 0.336-0.75 0.75-0.75h12c0.414 0 0.75 0.336 0.75 0.75v18c0 0.414-0.336 0.75-0.75 0.75h-12c-0.414 0-0.75-0.336-0.75-0.75v-18zM6.75 3.75v16.5h10.5v-16.5h-10.5z"></path>
<path d="M14.25 7c0-0.414 0.336-0.75 0.75-0.75h3.6c0.414 0 0.75 0.336 0.75 0.75s-0.336 0.75-0.75 0.75h-3.6c-0.414 0-0.75-0.336-0.75-0.75z"></path>
<path d="M4.25 7c0-0.414 0.336-0.75 0.75-0.75h3.6c0.414 0 0.75 0.336 0.75 0.75s-0.336 0.75-0.75 0.75h-3.6c-0.414 0-0.75-0.336-0.75-0.75z"></path>
<path d="M14.25 17c0-0.414 0.336-0.75 0.75-0.75h3.6c0.414 0 0.75 0.336 0.75 0.75s-0.336 0.75-0.75 0.75h-3.6c-0.414 0-0.75-0.336-0.75-0.75z"></path>
<path d="M4.25 17c0-0.414 0.336-0.75 0.75-0.75h3.6c0.414 0 0.75 0.336 0.75 0.75s-0.336 0.75-0.75 0.75h-3.6c-0.414 0-0.75-0.336-0.75-0.75z"></path>
<path d="M4.050 3c0-0.414 0.336-0.75 0.75-0.75h14.4c0.414 0 0.75 0.336 0.75 0.75s-0.336 0.75-0.75 0.75h-14.4c-0.414 0-0.75-0.336-0.75-0.75z"></path>
<path d="M4.050 21c0-0.414 0.336-0.75 0.75-0.75h14.4c0.414 0 0.75 0.336 0.75 0.75s-0.336 0.75-0.75 0.75h-14.4c-0.414 0-0.75-0.336-0.75-0.75z"></path>
<path d="M12.625 8.586c-0.139-0.21-0.374-0.336-0.626-0.336s-0.487 0.127-0.625 0.337l0.626 0.413 0.625-0.414zM12.001 10.417c-0.111 0.187-0.229 0.389-0.347 0.599-0.244 0.435-0.48 0.887-0.653 1.286-0.188 0.434-0.251 0.693-0.251 0.784 0 0.607 0.533 1.163 1.25 1.163s1.25-0.556 1.25-1.163c0-0.096-0.063-0.357-0.251-0.79-0.173-0.399-0.409-0.851-0.653-1.285-0.117-0.208-0.235-0.409-0.345-0.594zM14.376 11.7c0.187 0.433 0.374 0.949 0.374 1.386 0 1.497-1.267 2.663-2.75 2.663s-2.75-1.166-2.75-2.663c0-0.435 0.188-0.95 0.374-1.381 0.202-0.466 0.466-0.969 0.722-1.424 0.257-0.458 0.513-0.88 0.705-1.187 0.096-0.154 0.176-0.28 0.232-0.367 0.028-0.044 0.051-0.078 0.066-0.102l0.023-0.035 0.002-0.003c0 0 0 0 0.626 0.413 0.625-0.414 0.625-0.414 0.625-0.414l0.002 0.003 0.005 0.007 0.018 0.027c0.015 0.024 0.038 0.058 0.066 0.101 0.056 0.087 0.136 0.213 0.232 0.366 0.192 0.306 0.448 0.727 0.705 1.185 0.256 0.455 0.52 0.958 0.722 1.425z"></path>
</svg>