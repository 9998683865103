<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="candles" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<path d="M9.143 7.457c0-0.442 0.358-0.8 0.8-0.8h4.114c0.442 0 0.8 0.358 0.8 0.8v9.771c0 0.442-0.358 0.8-0.8 0.8h-4.114c-0.442 0-0.8-0.358-0.8-0.8v-9.771zM10.743 8.257v8.171h2.514v-8.171h-2.514z"></path>
<path d="M11.914 16.429c0.442 0 0.8 0.358 0.8 0.8v4.886c0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8v-4.886c0-0.442 0.358-0.8 0.8-0.8z"></path>
<path d="M11.914 1.771c0.442 0 0.8 0.358 0.8 0.8v4.886c0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8v-4.886c0-0.442 0.358-0.8 0.8-0.8z"></path>
<path d="M16.429 5.057c0-0.442 0.358-0.8 0.8-0.8h4.114c0.442 0 0.8 0.358 0.8 0.8v4.886c0 0.442-0.358 0.8-0.8 0.8h-4.114c-0.442 0-0.8-0.358-0.8-0.8v-4.886zM18.029 5.857v3.286h2.514v-3.286h-2.514z"></path>
<path d="M19.286 9.143c0.442 0 0.8 0.358 0.8 0.8v6.514c0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8v-6.514c0-0.442 0.358-0.8 0.8-0.8z"></path>
<path d="M19.286 1.771c0.442 0 0.8 0.358 0.8 0.8v2.486c0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8v-2.486c0-0.442 0.358-0.8 0.8-0.8z"></path>
<path d="M7.452 19.692c0 0.442-0.358 0.8-0.8 0.8h-4.114c-0.442 0-0.8-0.358-0.8-0.8v-4.886c0-0.442 0.358-0.8 0.8-0.8h4.114c0.442 0 0.8 0.358 0.8 0.8v4.886zM5.852 18.892v-3.286h-2.514v3.286h2.514z"></path>
<path d="M4.629 7.514c0.442 0 0.8 0.358 0.8 0.8v6.514c0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8v-6.514c0-0.442 0.358-0.8 0.8-0.8z"></path>
<path d="M4.629 18.914c0.442 0 0.8 0.358 0.8 0.8v2.4c0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8v-2.4c0-0.442 0.358-0.8 0.8-0.8z"></path>
</svg>