<script lang="ts">
  import type { HTMLInputAttributes } from "svelte/elements"
  import { getColorScheme } from "/@/util/color-scheme"
  import { isCryptocurrencyTransfers } from "/@/account-management/store/cryptocurrency"

  export let inputClass: string = ""
  export let id: string | null = null
  export let autocomplete: string = "off"
  export let name: string = undefined
  export let inputmode: HTMLInputAttributes["inputmode"] | null = null
  export let type: string = "text"
  export let value: any = undefined
  export let disabled: boolean = false
  export let placeholder: string = ""
  export let hasError: boolean = false
  let cssClass: string = ""
  export { cssClass as class }

  export let inputElem: any = null

  $: colorScheme = getColorScheme($isCryptocurrencyTransfers)
</script>

<div
  class="
    {cssClass}
    input-field
    flex
    flex-grow
    flex-row
    items-center
    rounded
    bg-input-field-mutator-controls
    text-input-fields-input-active
    focus-within:ring-1
  "
  style={$isCryptocurrencyTransfers &&
    `--tw-ring-color: ${colorScheme.MODAL_BORDER}`}
  class:ring-1={hasError}
  class:ring-alert-error={hasError}
  style:background={colorScheme.INPUTS_BACKGROUND}
>
  <slot name="prefix" />
  <slot
    name="input"
    class={inputClass}
    {id}
    {autocomplete}
    {type}
    {value}
    {inputmode}
    {disabled}
    {placeholder}
  >
    <!-- FIXME: focus state looks strange when prefix is provided. -->
    <input
      class="
        {inputClass}
        input-field-input-element
        flex
        flex-grow
        rounded
        border-none
        bg-transparent
        text-input-fields-input-active
        outline-none
      "
      class:focus-visible:outline-none={$$slots.prefix}
      {id}
      {autocomplete}
      {name}
      {type}
      {value}
      {inputmode}
      {disabled}
      {placeholder}
      bind:this={inputElem}
      on:input
      on:blur
      on:focus
      on:keydown
      on:change
    />
  </slot>
  <slot name="suffix" />
</div>
