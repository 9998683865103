<script lang="ts">
  import { cn } from "@tastyworks/ui-library"
  import TooltipButton from "/@/control/TooltipButton.svelte"

  export let label: string
  export let hint = null
  export let nameTooltip = ""
  let cssClass = ""
  export { cssClass as class }
</script>

<span
  class={cn(
    `form-label-container 
    flex
    items-end
    justify-between
    text-general-primary-label`,
    cssClass
  )}
>
  <span class="form-label">
    {#if label}
      <span class="form-label-text">
        {label}
      </span>
      {#if nameTooltip}
        <TooltipButton content={nameTooltip} />
      {/if}
    {/if}
  </span>

  <em class="form-label-hint font-x-small-500" class:hidden={!hint}>
    {hint}
  </em>
</span>
