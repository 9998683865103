<script context="module" lang="ts">
  // Don't allow modals to exceed the viewport size.
  // Use of vertical-meter in both directions is intentional.
  const MIN_MARGIN = "var(--vertical-meter)"

  // Dunno when these became a thing, but they need to be taken into account
  // when calculating the inset.
  const MAX_HEIGHT = "800px"
  const MAX_WIDTH = "800px"

  export function calculateInset(height: string, width: string) {
    const effectiveHeight = `min(${height}, ${MAX_HEIGHT})`
    const effectiveWidth = `min(${width}, ${MAX_WIDTH})`
    const x = `max(${MIN_MARGIN}, calc((100vw - ${effectiveWidth}) / 2))`
    const y = `max(${MIN_MARGIN}, calc((100vh - ${effectiveHeight}) / 2))`
    return `${y} ${x}`
  }
</script>

<script lang="ts">
  import media from "/@/layout/media"

  import clickOutside from "/@/util/click-outside"

  export let show = false
  export let lightbox = false
  export let height = "50vh"
  export let width = "50vw"
  export let inescapeableLightbox = false

  let cssStyle = ""
  let cssClass = ""

  export { cssClass as class }
  export { cssStyle as style }

  $: inset = calculateInset(height, $media.tablet ? "95vw" : width)
</script>

{#if inescapeableLightbox && show}
  <div
    class="inescapeable-lightbox fixed bottom-0 left-0 right-0 top-0 z-1 h-full w-full cursor-not-allowed bg-black/35"
    class:empty={!show}
  />
{/if}

<div
  class="modal fixed inset-0 {cssClass}"
  class:pointer-events-none={!lightbox}
  class:empty={!show}
  class:backdrop-blur-sm={lightbox}
  style:z-index="var(--modal-z-index)"
>
  <!-- FIXME: larger modal refactor required for more comprehensive styling/sizing, see ATP-2456 -->
  <div
    style={cssStyle}
    class="modal-content pointer-events-auto fixed"
    style:inset
    style:min-height={$media.mobile ? "450px" : "300px"}
    style:min-width={$media.mobile ? "300px" : "450px"}
    on:click-outside
    use:clickOutside={show}
  >
    <slot />
  </div>
</div>
