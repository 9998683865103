import Item from "./ButtonGroupItem.svelte"
import Root from "./ButtonGroupRoot.svelte"

export type { ButtonGroupItemProps } from "./ButtonGroupItem.svelte"
export type { ButtonGroupRootProps } from "./ButtonGroupRoot.svelte"

export const ButtonGroup = {
  Root,
  Item,
} as const
