import allyPath from "/@/assets/image/plaid/ally.svg"
import ameripriseFinancialPath from "/@/assets/image/plaid/ameriprise-financial.svg"
import bettermentPath from "/@/assets/image/plaid/betterment.svg"
import bofaPath from "/@/assets/image/plaid/bofa.svg"
import capitalOnePath from "/@/assets/image/plaid/capital-one.svg"
import charlesSchwabPath from "/@/assets/image/plaid/charles-schwab.svg"
import chasePath from "/@/assets/image/plaid/chase.svg"
import citiPath from "/@/assets/image/plaid/citi.svg"
import eTradePath from "/@/assets/image/plaid/e-trade.svg"
import interactiveBrokersPath from "/@/assets/image/plaid/interactive-brokers.svg"
import merrillPath from "/@/assets/image/plaid/merrill.svg"
import merrillEdgePath from "/@/assets/image/plaid/merrill-edge.svg"
import nfcuPath from "/@/assets/image/plaid/navy-federal-credit-union.svg"
import regionsPath from "/@/assets/image/plaid/regions.svg"
import tdBankPath from "/@/assets/image/plaid/td-bank.svg"
import truistPath from "/@/assets/image/plaid/truist.svg"
import usBankPath from "/@/assets/image/plaid/us-bank.svg"
import usaaPath from "/@/assets/image/plaid/usaa.svg"
import vanguardPath from "/@/assets/image/plaid/vanguard.svg"
import wealthfrontPath from "/@/assets/image/plaid/wealthfront.svg"
import wellsFargoPath from "/@/assets/image/plaid/wells-fargo.svg"

const MANUAL_ACAT_LONG_FORM_INSTITUTIONS = {
  AMERICAN_FUNDS: "ins_116477",
  AMERICAN_FUNDS_RETIREMENT_PLAN: "ins_116981",
  ASCENSUS_AMERICAN_FUNDS: "ins_118647",
  EMPOWER_AMERICAN_FUNDS: "ins_117463",
  THRIFT_SAVINGS_PLAN: "ins_116971",
}

export interface BankSearchDialogResult {
  publicToken: string
  metadata: any
}

export interface BrokerageSearchDialogResult {
  publicToken: string
  metadata: any
}

export interface PlaidInstitutionResult {
  base64Logo?: string
  colors: {
    primary: string
  }
  institutionId: string
  name: string
}

export interface PlaidInstitution {
  institutionId: string
  name: string
  imgPath: string
}

export enum PlaidProducts {
  AUTH = "auth",
  IDENTITY = "identity",
  INVESTMENTS = "investments",
}

export enum LinkEvents {
  OPEN = "OPEN",
}

export const defaultBanks = [
  {
    imgPath: bofaPath,
    institutionId: "ins_127989",
    name: "Bank of America",
  },
  {
    imgPath: chasePath,
    institutionId: "ins_56",
    name: "Chase",
  },
  {
    imgPath: usaaPath,
    institutionId: "ins_7",
    name: "USAA",
  },
  {
    imgPath: wellsFargoPath,
    institutionId: "ins_127991",
    name: "Wells Fargo",
  },
  {
    imgPath: usBankPath,
    institutionId: "ins_127990",
    name: "US Bank",
  },
  {
    imgPath: tdBankPath,
    institutionId: "td",
    name: "TD Bank",
  },
  {
    imgPath: citiPath,
    institutionId: "citi",
    name: "Citi",
  },
  {
    imgPath: nfcuPath,
    institutionId: "nfcu",
    name: "Navy Federal Credit Union",
  },
  {
    imgPath: truistPath,
    institutionId: "ins_130888",
    name: "Truist",
  },
  {
    imgPath: allyPath,
    institutionId: "ins_25",
    name: "Ally Bank",
  },
  {
    imgPath: regionsPath,
    institutionId: "regions",
    name: "Regions Bank",
  },
  {
    imgPath: capitalOnePath,
    institutionId: "ins_128026",
    name: "Capital One",
  },
]

export const defaultBrokerages = [
  {
    imgPath: eTradePath,
    institutionId: "ins_115589",
    name: "E*TRADE",
  },
  {
    imgPath: interactiveBrokersPath,
    institutionId: "ins_116530",
    name: "Interactive Brokers",
  },
  {
    imgPath: charlesSchwabPath,
    institutionId: "ins_11",
    name: "Charles Schwab",
  },
  {
    imgPath: ameripriseFinancialPath,
    institutionId: "ins_115604",
    name: "Ameriprise Financial",
  },
  {
    imgPath: bettermentPath,
    institutionId: "ins_115605",
    name: "Betterment",
  },
  {
    imgPath: merrillEdgePath,
    institutionId: "ins_115610",
    name: "Merrill Edge",
  },
  {
    imgPath: merrillPath,
    institutionId: "ins_115611",
    name: "Merrill",
  },
  {
    imgPath: vanguardPath,
    institutionId: "ins_115616",
    name: "Vanguard",
  },
  {
    imgPath: wealthfrontPath,
    institutionId: "ins_115617",
    name: "Wealthfront",
  },
]

function toInstitutionResults(institutions) {
  return institutions.map((institution) => {
    return {
      base64Logo: institution.logo,
      colors: {
        primary: institution.colors.primary,
      },
      institutionId: institution.institution_id,
      name: institution.name,
    }
  })
}

const url = `https://${
  import.meta.env.VITE_PLAID_ENVIRONMENT
}.plaid.com/institutions/search`

// NOTE: [KT] Plaid endpoint deprecated without a sunset date so it may be included into our API in the future
export async function fetchInstitutions(
  searchString: string,
  products,
  isBrokerageSearch: boolean = false
) {
  const params = {
    options: { include_display_data: true },
    products: products,
    public_key: import.meta.env.VITE_PLAID_PUBLIC_KEY,
    query: searchString,
  }

  const response = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  })

  const json = await response.json()

  let filteredInstitutions = json.institutions

  if (isBrokerageSearch) {
    filteredInstitutions = filteredInstitutions.filter(
      (institution) =>
        !Object.values(MANUAL_ACAT_LONG_FORM_INSTITUTIONS).includes(
          institution.institution_id
        )
    )
  }

  return toInstitutionResults(filteredInstitutions)
}
