<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="futuresWheat" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<path d="M12.655 2.634c-0.132-0.237-0.383-0.384-0.655-0.384s-0.522 0.147-0.655 0.384l0.655 0.366 0.655-0.366zM12 11c0.655 0.366 0.655 0.365 0.655 0.365l0-0.001 0.001-0.002 0.004-0.007 0.011-0.021c0.009-0.018 0.022-0.042 0.038-0.073 0.032-0.062 0.075-0.149 0.126-0.26 0.103-0.222 0.238-0.538 0.372-0.928 0.267-0.774 0.541-1.86 0.541-3.073s-0.274-2.299-0.541-3.073c-0.134-0.389-0.269-0.706-0.372-0.928-0.051-0.111-0.095-0.199-0.126-0.26-0.016-0.031-0.029-0.055-0.038-0.073l-0.011-0.021-0.005-0.009-0.001-0.002c-0-0 0 0-0.655 0.366-0.655-0.366-0.655-0.366-0.655-0.366l-0.001 0.002-0.005 0.009-0.011 0.021c-0.009 0.018-0.022 0.042-0.038 0.073-0.032 0.062-0.075 0.149-0.126 0.26-0.103 0.222-0.238 0.538-0.372 0.928-0.267 0.774-0.541 1.86-0.541 3.073s0.274 2.299 0.541 3.073c0.135 0.389 0.269 0.706 0.372 0.928 0.051 0.111 0.095 0.199 0.126 0.26 0.016 0.031 0.029 0.055 0.038 0.073l0.011 0.021 0.004 0.007 0.001 0.002 0.001 0.001c0 0 0 0 0.655-0.365zM12 11l0.655 0.365c-0.132 0.237-0.384 0.385-0.655 0.385s-0.523-0.147-0.655-0.385l0.655-0.365zM12.656 10.636v0c0 0 0 0 0 0zM12 8.889c-0.143-0.556-0.25-1.203-0.25-1.889s0.107-1.332 0.25-1.889c0.143 0.556 0.25 1.203 0.25 1.889s-0.107 1.332-0.25 1.889z"></path>
<path d="M17.747 8.067c0.020-0.221-0.060-0.44-0.217-0.597s-0.376-0.236-0.597-0.217l0.067 0.747 0.747 0.067zM12 13c0.067 0.747 0.068 0.747 0.068 0.747l0.001-0 0.003-0 0.008-0.001 0.025-0.003c0.021-0.002 0.049-0.006 0.084-0.010 0.070-0.009 0.168-0.024 0.288-0.045 0.24-0.043 0.573-0.117 0.953-0.237 0.751-0.239 1.734-0.677 2.539-1.482s1.244-1.788 1.482-2.539c0.121-0.38 0.194-0.713 0.237-0.953 0.022-0.12 0.036-0.218 0.045-0.288 0.005-0.035 0.008-0.063 0.010-0.084l0.003-0.025 0.001-0.008 0-0.003 0-0.002c0-0 0 0-0.747-0.067-0.067-0.747-0.067-0.747-0.067-0.747l-0.013 0.001-0.025 0.003c-0.021 0.002-0.049 0.006-0.084 0.010-0.070 0.009-0.168 0.024-0.288 0.045-0.24 0.043-0.573 0.117-0.953 0.237-0.751 0.238-1.734 0.677-2.539 1.482s-1.243 1.788-1.482 2.539c-0.121 0.38-0.194 0.713-0.237 0.953-0.022 0.12-0.036 0.218-0.045 0.288-0.005 0.035-0.008 0.063-0.010 0.084l-0.003 0.025-0.001 0.008-0 0.003-0 0.002c0 0 0 0 0.747 0.067zM12 13l0.068 0.747c-0.221 0.020-0.441-0.059-0.598-0.217s-0.237-0.376-0.217-0.598l0.747 0.067zM12.981 12.019c0.198-0.623 0.54-1.356 1.111-1.927s1.304-0.913 1.927-1.111c-0.198 0.623-0.54 1.356-1.111 1.927s-1.305 0.913-1.927 1.111z"></path>
<path d="M12 10.25c0.414 0 0.75 0.336 0.75 0.75v10c0 0.414-0.336 0.75-0.75 0.75s-0.75-0.336-0.75-0.75v-10c0-0.414 0.336-0.75 0.75-0.75z"></path>
<path d="M18.747 11.067c0.020-0.221-0.060-0.44-0.217-0.597s-0.376-0.237-0.597-0.217l0.067 0.747 0.747 0.067zM12 17c-0.747-0.067-0.747-0.067-0.747-0.067l0-0.002 0-0.003 0.001-0.009 0.003-0.029c0.003-0.024 0.006-0.056 0.012-0.097 0.011-0.082 0.028-0.197 0.053-0.339 0.051-0.283 0.138-0.676 0.28-1.125 0.282-0.888 0.799-2.044 1.742-2.986s2.097-1.459 2.986-1.742c0.449-0.143 0.841-0.229 1.125-0.28 0.142-0.026 0.257-0.043 0.339-0.053 0.041-0.005 0.074-0.009 0.097-0.012l0.029-0.003 0.009-0.001 0.003-0 0.002-0c0 0 0 0 0.067 0.747 0.747 0.067 0.747 0.067 0.747 0.067l-0 0.002-0 0.003-0.001 0.009-0.003 0.029c-0.003 0.024-0.006 0.056-0.012 0.097-0.011 0.082-0.028 0.197-0.053 0.339-0.051 0.283-0.138 0.676-0.28 1.125-0.282 0.888-0.799 2.044-1.741 2.986s-2.097 1.459-2.986 1.741c-0.449 0.143-0.841 0.229-1.125 0.28-0.142 0.026-0.257 0.043-0.339 0.053-0.041 0.005-0.074 0.009-0.097 0.012l-0.029 0.003-0.009 0.001-0.003 0-0.001 0c-0 0-0.001 0-0.068-0.747zM12 17l-0.747-0.067c-0.020 0.221 0.060 0.441 0.217 0.598s0.376 0.237 0.597 0.217l-0.067-0.747zM12.954 16.046c0.084-0.023 0.172-0.049 0.262-0.078 0.761-0.242 1.669-0.662 2.379-1.372s1.131-1.618 1.372-2.379c0.029-0.090 0.055-0.178 0.078-0.262-0.084 0.023-0.172 0.049-0.262 0.078-0.761 0.242-1.669 0.662-2.379 1.372s-1.131 1.618-1.372 2.379c-0.029 0.090-0.055 0.178-0.078 0.262z"></path>
<path d="M18.747 15.067c0.020-0.221-0.060-0.44-0.217-0.597s-0.376-0.237-0.597-0.217l0.067 0.747 0.747 0.067zM12 21c-0.747-0.067-0.747-0.067-0.747-0.067l0-0.002 0-0.003 0.001-0.009 0.003-0.029c0.003-0.024 0.006-0.056 0.012-0.097 0.011-0.082 0.028-0.197 0.053-0.339 0.051-0.283 0.138-0.676 0.28-1.125 0.282-0.888 0.799-2.044 1.742-2.986s2.097-1.459 2.986-1.741c0.449-0.143 0.841-0.229 1.125-0.28 0.142-0.026 0.257-0.043 0.339-0.053 0.041-0.005 0.074-0.009 0.097-0.012l0.029-0.003 0.009-0.001 0.003-0 0.002-0c0 0 0 0 0.067 0.747 0.747 0.067 0.747 0.067 0.747 0.067l-0 0.002-0 0.003-0.001 0.009-0.003 0.028c-0.003 0.024-0.006 0.056-0.012 0.097-0.011 0.082-0.028 0.197-0.053 0.339-0.051 0.283-0.138 0.676-0.28 1.125-0.282 0.888-0.799 2.044-1.741 2.986s-2.097 1.459-2.986 1.741c-0.449 0.143-0.841 0.229-1.125 0.28-0.142 0.026-0.257 0.043-0.339 0.053-0.041 0.005-0.074 0.009-0.097 0.012l-0.029 0.003-0.009 0.001-0.003 0-0.001 0c-0 0-0.001 0-0.068-0.747zM12 21l-0.747-0.067c-0.020 0.221 0.060 0.441 0.217 0.598s0.376 0.237 0.597 0.217l-0.067-0.747zM12.954 20.046c0.084-0.023 0.172-0.049 0.262-0.078 0.761-0.242 1.669-0.662 2.379-1.372s1.131-1.618 1.372-2.379c0.029-0.090 0.055-0.178 0.078-0.262-0.084 0.023-0.172 0.049-0.262 0.078-0.761 0.242-1.669 0.662-2.379 1.372s-1.131 1.618-1.372 2.379c-0.029 0.090-0.055 0.178-0.078 0.262z"></path>
<path d="M6.253 8.067c-0.020-0.221 0.060-0.44 0.217-0.597s0.376-0.236 0.597-0.217l-0.067 0.747-0.747 0.067zM12 13c-0.067 0.747-0.068 0.747-0.068 0.747l-0.001-0-0.003-0-0.008-0.001-0.025-0.003c-0.021-0.002-0.049-0.006-0.084-0.010-0.070-0.009-0.168-0.024-0.288-0.045-0.24-0.043-0.573-0.117-0.953-0.237-0.751-0.239-1.734-0.677-2.539-1.482s-1.243-1.788-1.482-2.539c-0.121-0.38-0.194-0.713-0.237-0.953-0.022-0.12-0.036-0.218-0.045-0.288-0.005-0.035-0.008-0.063-0.010-0.084l-0.003-0.025-0.001-0.008-0-0.003-0-0.002c-0-0 0 0 0.747-0.067 0.067-0.747 0.067-0.747 0.067-0.747l0.002 0 0.011 0.001 0.025 0.003c0.021 0.002 0.049 0.006 0.084 0.010 0.070 0.009 0.168 0.024 0.288 0.045 0.24 0.043 0.573 0.117 0.953 0.237 0.751 0.238 1.734 0.677 2.539 1.482s1.244 1.788 1.482 2.539c0.121 0.38 0.194 0.713 0.237 0.953 0.022 0.12 0.036 0.218 0.045 0.288 0.005 0.035 0.008 0.063 0.010 0.084l0.003 0.025 0.001 0.008 0 0.003 0 0.002c0 0 0 0-0.747 0.067zM12 13l-0.068 0.747c0.221 0.020 0.441-0.059 0.598-0.217s0.237-0.376 0.217-0.598l-0.747 0.067zM11.019 12.019c-0.198-0.623-0.54-1.356-1.111-1.927s-1.304-0.913-1.927-1.111c0.198 0.623 0.54 1.356 1.111 1.927s1.304 0.913 1.927 1.111z"></path>
<path d="M5.253 11.067c-0.020-0.221 0.060-0.44 0.217-0.597s0.376-0.237 0.597-0.217l-0.067 0.747-0.747 0.067zM12 17c0.747-0.067 0.747-0.067 0.747-0.067l-0-0.002-0-0.003-0.001-0.009-0.003-0.029c-0.003-0.024-0.006-0.056-0.012-0.097-0.011-0.082-0.028-0.197-0.053-0.339-0.051-0.283-0.138-0.676-0.28-1.125-0.282-0.888-0.799-2.044-1.741-2.986s-2.098-1.459-2.986-1.742c-0.449-0.143-0.841-0.229-1.124-0.28-0.142-0.026-0.257-0.043-0.339-0.053-0.041-0.005-0.074-0.009-0.097-0.012l-0.029-0.003-0.009-0.001-0.005-0.001c-0 0 0 0-0.067 0.747-0.747 0.067-0.747 0.067-0.747 0.067l0 0.002 0 0.003 0.001 0.009 0.003 0.029c0.003 0.024 0.006 0.056 0.012 0.097 0.011 0.082 0.028 0.197 0.053 0.339 0.051 0.283 0.138 0.676 0.28 1.125 0.282 0.888 0.799 2.044 1.741 2.986s2.098 1.459 2.986 1.741c0.449 0.143 0.841 0.229 1.125 0.28 0.142 0.026 0.257 0.043 0.339 0.053 0.041 0.005 0.074 0.009 0.097 0.012l0.029 0.003 0.009 0.001 0.003 0 0.001 0c0 0 0.001 0 0.068-0.747zM12 17l0.747-0.067c0.020 0.221-0.060 0.441-0.217 0.598s-0.376 0.237-0.597 0.217l0.067-0.747zM11.046 16.046c-0.084-0.023-0.172-0.049-0.262-0.078-0.761-0.242-1.669-0.662-2.379-1.372s-1.131-1.618-1.372-2.379c-0.029-0.090-0.055-0.178-0.078-0.262 0.084 0.023 0.172 0.049 0.262 0.078 0.761 0.242 1.669 0.662 2.379 1.372s1.131 1.618 1.372 2.379c0.029 0.090 0.055 0.178 0.078 0.262z"></path>
<path d="M5.253 15.067c-0.020-0.221 0.060-0.44 0.217-0.597s0.376-0.237 0.597-0.217l-0.067 0.747-0.747 0.067zM12 21c0.747-0.067 0.747-0.067 0.747-0.067l-0-0.002-0-0.003-0.001-0.009-0.003-0.029c-0.003-0.024-0.006-0.056-0.012-0.097-0.011-0.082-0.028-0.197-0.053-0.339-0.051-0.283-0.138-0.676-0.28-1.125-0.282-0.888-0.799-2.044-1.741-2.986s-2.098-1.459-2.986-1.741c-0.449-0.143-0.841-0.229-1.124-0.28-0.142-0.026-0.257-0.043-0.339-0.053-0.041-0.005-0.074-0.009-0.097-0.012l-0.029-0.003-0.009-0.001-0.005-0.001c-0 0 0 0-0.067 0.747-0.747 0.067-0.747 0.067-0.747 0.067l0 0.002 0 0.003 0.001 0.009 0.003 0.028c0.003 0.024 0.006 0.056 0.012 0.097 0.011 0.082 0.028 0.197 0.053 0.339 0.051 0.283 0.138 0.676 0.28 1.125 0.282 0.888 0.799 2.044 1.741 2.986s2.098 1.459 2.986 1.741c0.449 0.143 0.841 0.229 1.125 0.28 0.142 0.026 0.257 0.043 0.339 0.053 0.041 0.005 0.074 0.009 0.097 0.012l0.029 0.003 0.009 0.001 0.003 0 0.001 0c0 0 0.001 0 0.068-0.747zM12 21l0.747-0.067c0.020 0.221-0.060 0.441-0.217 0.598s-0.376 0.237-0.597 0.217l0.067-0.747zM11.046 20.046c-0.084-0.023-0.172-0.049-0.262-0.078-0.761-0.242-1.669-0.662-2.379-1.372s-1.131-1.618-1.372-2.379c-0.029-0.090-0.055-0.178-0.078-0.262 0.084 0.023 0.172 0.049 0.262 0.078 0.761 0.242 1.669 0.662 2.379 1.372s1.131 1.618 1.372 2.379c0.029 0.090 0.055 0.178 0.078 0.262z"></path>
</svg>