import { dequal as isEqual } from "dequal/lite"
import type { Writable } from "svelte/store"
import type { AnyObject } from "yup"

function subscribeOnce<T>(observable: Writable<T>): Promise<T> {
  return new Promise((resolve) => {
    const unsubscribe = observable.subscribe(resolve)
    unsubscribe() // immediately invoke to unsubscribe
  })
}

function update<T extends AnyObject>(
  object: Writable<T>,
  path: string,
  value: any
): void {
  object.update((o) => {
    set(o, path, value)
    return o
  })
}

function cloneDeep<T>(object: T): T {
  return JSON.parse(JSON.stringify(object))
}

function isNullish(value: unknown): boolean {
  return value === undefined || value === null
}

function isEmpty(object: unknown): boolean {
  return isNullish(object) || Object.keys(object as object).length <= 0
}

function getValues(object: Record<string, any>): any[] {
  let results: any[] = []

  for (const [, value] of Object.entries(object)) {
    const values =
      typeof value === "object" && value !== null ? getValues(value) : [value]
    results = [...results, ...values]
  }

  return results
}

// TODO: refactor this so as not to rely directly on yup's API
// This should use dependency injection, with a default callback which may assume
// yup as the validation schema
function getErrorsFromSchema<Inf extends AnyObject>(
  initialValues: Record<keyof Inf, any> | undefined,
  schema: NonNullable<Inf>,
  errors: Partial<Record<keyof Inf, any>> = {}
): Record<string, any> {
  for (const key in schema) {
    switch (true) {
      case schema[key].type === "object" && !isEmpty(schema[key].fields): {
        errors[key] = getErrorsFromSchema(
          initialValues?.[key],
          schema[key].fields,
          { ...errors[key] }
        )
        break
      }

      case schema[key].type === "array": {
        const values =
          initialValues && initialValues[key] ? initialValues[key] : []
        errors[key] = values.map((value: any) => {
          const innerError = getErrorsFromSchema(
            value,
            schema[key].innerType!.fields!,
            { ...errors[key] }
          )

          return Object.keys(innerError).length > 0 ? innerError : ""
        })
        break
      }

      default: {
        errors[key] = ""
      }
    }
  }

  return errors
}

const deepEqual = isEqual

function assignDeep<T>(object: T, value: any): any {
  if (Array.isArray(object)) {
    return object.map((o) => assignDeep(o, value))
  }

  const copy: Record<string, any> = {}
  for (const key in object as Record<string, any>) {
    copy[key] =
      typeof (object as Record<string, any>)[key] === "object" &&
      !isNullish((object as Record<string, any>)[key])
        ? assignDeep((object as Record<string, any>)[key], value)
        : value
  }
  return copy
}

function set(
  object: Record<string, any>,
  path: string | string[],
  value: any
): Record<string, any> {
  if (!Array.isArray(path)) {
    path = path.toString().match(/[^.[\]"]+/g) || []
  }

  const result = path
    .slice(0, -1)
    // TODO: replace this reduce with something more readable
    .reduce(
      (accumulator, key, index) =>
        Object(accumulator[key]) === accumulator[key]
          ? accumulator[key]
          : (accumulator[key] =
              Math.trunc(Math.abs(+path[index + 1])) === +path[index + 1]
                ? []
                : {}),
      object
    )

  result[path[path.length - 1]] = value

  return object
}

export const util = {
  assignDeep,
  cloneDeep,
  deepEqual,
  getErrorsFromSchema,
  getValues,
  isEmpty,
  isNullish,
  set,
  subscribeOnce,
  update,
}
