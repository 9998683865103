<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="unlinked" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<path d="M18.885 3.327c-0.796-0.719-1.932-0.682-2.601-0.013l-3.706 3.706c-0.719 0.796-0.682 1.932-0.013 2.601 0.312 0.312 0.312 0.819 0 1.131s-0.819 0.312-1.131 0c-1.323-1.323-1.289-3.445-0.024-4.825 0.008-0.009 0.016-0.017 0.024-0.025l3.719-3.719c1.323-1.323 3.445-1.289 4.825-0.024 0.009 0.008 0.017 0.016 0.025 0.024l1.814 1.814c1.323 1.323 1.289 3.445 0.024 4.825-0.008 0.009-0.016 0.017-0.024 0.025l-3.719 3.719c-1.323 1.323-3.445 1.289-4.825 0.024-0.326-0.299-0.348-0.805-0.049-1.13s0.805-0.348 1.13-0.049c0.797 0.73 1.94 0.696 2.613 0.024l3.706-3.706c0.719-0.796 0.682-1.932 0.013-2.601l-1.801-1.801z"></path>
<path d="M5.927 11.41c1.38-1.265 3.502-1.299 4.825 0.024 0.312 0.312 0.312 0.819 0 1.131s-0.819 0.312-1.131 0c-0.669-0.669-1.805-0.706-2.601 0.013l-3.706 3.706c-0.669 0.669-0.706 1.805 0.013 2.601l1.801 1.801c0.669 0.669 1.805 0.706 2.601-0.013l3.706-3.705c0.672-0.672 0.706-1.816-0.024-2.613-0.299-0.326-0.277-0.832 0.049-1.13s0.832-0.277 1.13 0.049c1.265 1.38 1.299 3.502-0.024 4.825l-3.719 3.719c-0.008 0.008-0.017 0.016-0.025 0.024-1.38 1.265-3.502 1.299-4.825-0.024l-1.814-1.814c-0.008-0.008-0.016-0.017-0.024-0.025-1.265-1.38-1.299-3.502 0.024-4.825l3.719-3.719c0.008-0.008 0.017-0.016 0.025-0.024z"></path>
<path d="M17.018 17.018c0.234-0.234 0.614-0.234 0.849 0l3.628 3.628c0.234 0.234 0.234 0.614 0 0.849s-0.614 0.234-0.849 0l-3.628-3.628c-0.234-0.234-0.234-0.614 0-0.849z"></path>
<path d="M14.721 16.842c0.331 0 0.6 0.269 0.6 0.6v3.628c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-3.628c0-0.331 0.269-0.6 0.6-0.6z"></path>
<path d="M16.842 14.721c0-0.331 0.269-0.6 0.6-0.6h3.628c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-3.628c-0.331 0-0.6-0.269-0.6-0.6z"></path>
<path d="M2.505 2.505c0.234-0.234 0.614-0.234 0.849 0l3.628 3.628c0.234 0.234 0.234 0.614 0 0.849s-0.614 0.234-0.849 0l-3.628-3.628c-0.234-0.234-0.234-0.614 0-0.849z"></path>
<path d="M9.279 2.33c0.331 0 0.6 0.269 0.6 0.6v3.628c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-3.628c0-0.331 0.269-0.6 0.6-0.6z"></path>
<path d="M2.33 9.279c0-0.331 0.269-0.6 0.6-0.6h3.628c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-3.628c-0.331 0-0.6-0.269-0.6-0.6z"></path>
</svg>