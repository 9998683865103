<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="bars" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<path d="M18.111 5.15c0.442 0 0.8 0.358 0.8 0.8v12.101c0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8v-12.101c0-0.442 0.358-0.8 0.8-0.8z"></path>
<path d="M14.867 5.95c0-0.442 0.358-0.8 0.8-0.8h2.444c0.442 0 0.8 0.358 0.8 0.8s-0.358 0.8-0.8 0.8h-2.444c-0.442 0-0.8-0.358-0.8-0.8z"></path>
<path d="M17.311 15.371c0-0.442 0.358-0.8 0.8-0.8h2.445c0.442 0 0.8 0.358 0.8 0.8s-0.358 0.8-0.8 0.8h-2.445c-0.442 0-0.8-0.358-0.8-0.8z"></path>
<path d="M5.889 5.15c0.442 0 0.8 0.358 0.8 0.8v8.038c0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8v-8.038c0-0.442 0.358-0.8 0.8-0.8z"></path>
<path d="M2.644 5.95c0-0.442 0.358-0.8 0.8-0.8h2.444c0.442 0 0.8 0.358 0.8 0.8s-0.358 0.8-0.8 0.8h-2.444c-0.442 0-0.8-0.358-0.8-0.8z"></path>
<path d="M5.089 13.988c0-0.442 0.358-0.8 0.8-0.8h2.532c0.442 0 0.8 0.358 0.8 0.8s-0.358 0.8-0.8 0.8h-2.532c-0.442 0-0.8-0.358-0.8-0.8z"></path>
<path d="M12 1.779c0.442 0 0.8 0.358 0.8 0.8v18.843c0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8v-18.843c0-0.442 0.358-0.8 0.8-0.8z"></path>
<path d="M11.2 2.579c0-0.442 0.358-0.8 0.8-0.8h2.444c0.442 0 0.8 0.358 0.8 0.8s-0.358 0.8-0.8 0.8h-2.444c-0.442 0-0.8-0.358-0.8-0.8z"></path>
<path d="M8.756 18.051c0-0.442 0.358-0.8 0.8-0.8h2.444c0.442 0 0.8 0.358 0.8 0.8s-0.358 0.8-0.8 0.8h-2.444c-0.442 0-0.8-0.358-0.8-0.8z"></path>
</svg>