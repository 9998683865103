/**
 * @module cam/account-management/forms/util
 */
import {
  createForm as createFormStore,
  createScope,
} from "/@/account-management/store"
import { createForm as createBasicForm } from "@tastyworks/svelte-forms-lib"
import { isNilOrEmpty, ValidateYup } from "@tastyworks/tastyworks-api"
import { get, writable } from "svelte/store"
import { reach } from "yup"
import { _ } from "svelte-i18n"

export function fromOptionValues(optionValues) {
  return optionValues.map((value) => ({ value }))
}

/**
 * @description gets a value by path.
 * @param {object} object
 * @param {string} path - NOTE: [KT] only supports dot notation, e.g. foo.bar.baz
 */
export function getByPath(object, path) {
  return path.split(".").reduce((accumulator, current) => {
    if (isNilOrEmpty(accumulator)) {
      return
    }

    return accumulator[current]
  }, object)
}

/**
 * @description sets a value by path
 * @param {object} object
 * @param {string} path - [KT] only supports dot notation, e.g. foo.bar.baz
 * @param {object} value
 */
export function setByPath(object, path, value) {
  path.split(".").reduce((accumulator, current, index, array) => {
    if (index === array.length - 1) {
      accumulator[current] = value

      return accumulator[current]
    }

    if (accumulator[current] === undefined) {
      accumulator[current] = {}
    }

    return accumulator[current]
  }, object)
}

/**
 * @description looks up associated text for an option
 * @param {object} optionEntry - option entry to look-up e.g. { value: "replace_with_key" }
 * @param {object} options
 */
export function getOptionText(optionEntry, options) {
  if (optionEntry.text === null || optionEntry.text === undefined) {
    return lookupOptionTranslation(optionEntry.value, options)
  }

  return optionEntry.text
}

/**
 * @description looks up associated description for an option
 * @param {object} optionEntry - option entry to look-up e.g. { value: "replace_with_key" }
 * @param {object} options
 */
export function getOptionDescriptionText(optionEntry, options) {
  return lookupOptionTranslation(`${optionEntry.value}.description`, options)
}

export function lookupOptionTranslation(value, options) {
  /**
   * TODO: [KT] there may be a bug upstream in svelte-i18n has a bug with nested properties
   * we should be able to lookup the translation directly instead of looking up the json
   */
  if (isPlainObject(options)) {
    const translation = options[value]
    if (translation === null || translation === undefined) {
      return ""
    }

    return translation
  }

  return value
}

/**
 * @description visits each field key and invokes callback
 * @param {function} forEachPath(path) - update cb
 * @param {object} schema - Yup
 * @param {string} path - only supports dot notation, e.g. foo.bar.baz
 */
export function recursivelyForEachPath(forEachPath, schema, path = "") {
  if (schema.type === "object") {
    for (const fieldName of Object.keys(schema.fields)) {
      let prefix = ""
      if (path !== "") {
        prefix = path + "."
      }
      recursivelyForEachPath(
        forEachPath,
        schema.fields[fieldName],
        prefix + fieldName
      )
    }
  } else {
    forEachPath(path)
  }
}

function recursivelyTouch(updateTouched, parent, path) {
  recursivelyForEachPath((path) => updateTouched(path, true), parent, path)
}

/**
 * NOTE: [KT] this is a simple implementation that doesn't work in various edge cases.
 * If we need more something more complex we can import a library such as lodash
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/toString#using_tostring_to_detect_object_class
 */
export function isPlainObject(object) {
  return Object.prototype.toString.call(object) === "[object Object]"
}

// NOTE: [KT] from svelte-forms-lib, limited clone capability, copying until we push this into the library
function cloneDeep(object) {
  return JSON.parse(JSON.stringify(object))
}

export function createForm({
  validationSchema,
  onSubmit,
  initialValues = null,
  initialScope = null,
}) {
  let formContext = createBasicForm({
    // NOTE: [KT] workaround for typescript warning for default arguments losing type information
    initialValues: initialValues ?? {},
    onSubmit,
    validationSchema,
  })

  const isReadOnly = writable(false)
  const scope = createScope(initialScope)
  const form = createFormStore(formContext.form)
  formContext.form = form

  // TODO: [KT] push this into the svelte-forms-lib to defensively copy values
  function updateInitialValues(values) {
    const clonedValues = cloneDeep(values)

    return formContext.updateInitialValues(clonedValues)
  }

  let context = {
    ...formContext,
    initializeScope: (initialScope) => {
      scope.set(initialScope)
      form.initializeScope(initialScope, updateInitialValues)
    },
    isReadOnly,
    scope,
    touchAll: () => {
      recursivelyTouch(
        formContext.updateTouched,
        validationSchema.describe(),
        ""
      )
    },
    updateFieldError: (name, errorKey) => {
      // NOTE: [KT] as this does not update the validation schema, field errors set in this manner will only last until
      // validation is run again
      formContext.errors.update((existing) => {
        setByPath(existing, name, errorKey)

        return existing
      })
    },
    updateInitialValues,
    updateReadOnly: (value) => {
      isReadOnly.set(value)
    },
    validationSchema,
  }

  if (initialScope !== null) {
    context.initializeScope(initialScope)
  }

  form.subscribe((value) => {
    scope.castRawValueAll(validationSchema, value)
  })

  return context
}

/**
 * @description gets translation key to retrieve text from locales file
 * @param {object} validationSchema
 * @param {string} name - field name
 * @returns {string} field translation key
 */
export function findFieldTranslationKey(validationSchema, name) {
  return `model.${ValidateYup.findFieldTranslationKey(validationSchema, name)}`
}

export function isFieldRequired(validationSchema, fieldName) {
  const { nullable, optional } = reach(validationSchema, fieldName).describe()
  return !optional && !nullable
}

/**
 * @description given an unknown yup error message, return a formatted error message
 *
 * @param {?} errors the raw result of validation
 * @returns {string | null} the formatted error message
 */
export function formatErrorMessage(errors) {
  const $_ = get(_)
  if (errors === "") {
    return null
  }

  if (typeof errors === "string") {
    return $_(errors)
  }

  if (isPlainObject(errors)) {
    const { key: errorKey, ...errorValues } = errors

    return $_(errorKey, errorValues)
  }

  return null
}
