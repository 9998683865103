<script lang="ts">
  import { key } from "@tastyworks/svelte-forms-lib"
  import { getContext } from "svelte"
  import { formatErrorMessage, getByPath } from "./util"
  import { FormContext } from "/@/account-management/form"
  import userAnalytics, { UserAnalyticsTag } from "/@/util/user-analytics"

  const { errors } = getContext<FormContext>(key)

  export let name: string
  export let userAnalyticsTag: UserAnalyticsTag = null
  export let hidden = false

  $: errorMessage = formatErrorMessage(getByPath($errors, name))
</script>

{#if errorMessage}
  <div
    class="
      form-error-message
      mt-1.5
      flex
      flex-row
      items-center"
    class:hidden
  >
    <small
      class="
        text-alerts-error
        font-small-500"
      use:userAnalytics={userAnalyticsTag}>{errorMessage}</small
    >
  </div>
{/if}
