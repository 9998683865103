<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="loginPasscode" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<path d="M3.282 1.8c-0.82 0-1.482 0.666-1.482 1.482v17.435c0 0.821 0.666 1.482 1.482 1.482h17.428c0.822 0 1.483-0.66 1.491-1.476v-17.441c0-0.821-0.666-1.482-1.482-1.482h-17.437zM0.2 3.282c0-1.695 1.373-3.083 3.082-3.083h17.437c1.695 0 3.082 1.374 3.082 3.083v17.45c-0.012 1.694-1.385 3.068-3.091 3.068h-17.428c-1.695 0-3.082-1.374-3.082-3.082v-17.435z"></path>
<path d="M6.498 5.267c-0.68 0-1.233 0.552-1.233 1.233s0.552 1.233 1.233 1.233c0.68 0 1.233-0.552 1.233-1.233s-0.552-1.233-1.233-1.233zM4.065 6.5c0-1.344 1.089-2.433 2.433-2.433s2.433 1.090 2.433 2.433c0 1.344-1.089 2.433-2.433 2.433s-2.433-1.090-2.433-2.433z"></path>
<path d="M11.995 5.267c-0.681 0-1.233 0.552-1.233 1.233s0.552 1.233 1.233 1.233c0.681 0 1.233-0.552 1.233-1.233s-0.552-1.233-1.233-1.233zM9.563 6.5c0-1.344 1.089-2.433 2.433-2.433s2.433 1.090 2.433 2.433c0 1.344-1.089 2.433-2.433 2.433s-2.433-1.090-2.433-2.433z"></path>
<path d="M17.493 8.333c1.012 0 1.833-0.821 1.833-1.833s-0.82-1.833-1.833-1.833c-1.012 0-1.833 0.821-1.833 1.833s0.82 1.833 1.833 1.833z"></path>
<path d="M17.493 5.267c-0.681 0-1.233 0.552-1.233 1.233s0.552 1.233 1.233 1.233c0.681 0 1.233-0.552 1.233-1.233s-0.552-1.233-1.233-1.233zM15.060 6.5c0-1.344 1.089-2.433 2.433-2.433s2.433 1.090 2.433 2.433c0 1.344-1.089 2.433-2.433 2.433s-2.433-1.090-2.433-2.433z"></path>
<path d="M6.498 13.833c1.012 0 1.833-0.821 1.833-1.833s-0.82-1.833-1.833-1.833-1.833 0.821-1.833 1.833c0 1.013 0.82 1.833 1.833 1.833z"></path>
<path d="M6.498 10.767c-0.68 0-1.233 0.552-1.233 1.233s0.552 1.233 1.233 1.233 1.233-0.552 1.233-1.233c0-0.681-0.552-1.233-1.233-1.233zM4.065 12c0-1.344 1.089-2.433 2.433-2.433s2.433 1.090 2.433 2.433c0 1.344-1.089 2.433-2.433 2.433s-2.433-1.090-2.433-2.433z"></path>
<path d="M11.995 10.767c-0.681 0-1.233 0.552-1.233 1.233s0.552 1.233 1.233 1.233 1.233-0.552 1.233-1.233c0-0.681-0.552-1.233-1.233-1.233zM9.563 12c0-1.344 1.089-2.433 2.433-2.433s2.433 1.090 2.433 2.433c0 1.344-1.089 2.433-2.433 2.433s-2.433-1.090-2.433-2.433z"></path>
<path d="M17.493 10.767c-0.681 0-1.233 0.552-1.233 1.233s0.552 1.233 1.233 1.233 1.233-0.552 1.233-1.233c0-0.681-0.552-1.233-1.233-1.233zM15.060 12c0-1.344 1.089-2.433 2.433-2.433s2.433 1.090 2.433 2.433c0 1.344-1.089 2.433-2.433 2.433s-2.433-1.090-2.433-2.433z"></path>
<path d="M6.498 16.266c-0.68 0-1.233 0.552-1.233 1.233s0.552 1.233 1.233 1.233 1.233-0.552 1.233-1.233c0-0.681-0.552-1.233-1.233-1.233zM4.065 17.5c0-1.344 1.089-2.433 2.433-2.433s2.433 1.090 2.433 2.433c0 1.344-1.089 2.433-2.433 2.433s-2.433-1.090-2.433-2.433z"></path>
<path d="M11.995 19.333c1.012 0 1.833-0.821 1.833-1.833s-0.821-1.833-1.833-1.833-1.833 0.821-1.833 1.833c0 1.013 0.82 1.833 1.833 1.833z"></path>
<path d="M11.995 16.266c-0.681 0-1.233 0.552-1.233 1.233s0.552 1.233 1.233 1.233 1.233-0.552 1.233-1.233c0-0.681-0.552-1.233-1.233-1.233zM9.563 17.5c0-1.344 1.089-2.433 2.433-2.433s2.433 1.090 2.433 2.433c0 1.344-1.089 2.433-2.433 2.433s-2.433-1.090-2.433-2.433z"></path>
<path d="M17.493 16.266c-0.681 0-1.233 0.552-1.233 1.233s0.552 1.233 1.233 1.233 1.233-0.552 1.233-1.233c0-0.681-0.552-1.233-1.233-1.233zM15.060 17.5c0-1.344 1.089-2.433 2.433-2.433s2.433 1.090 2.433 2.433c0 1.344-1.089 2.433-2.433 2.433s-2.433-1.090-2.433-2.433z"></path>
</svg>