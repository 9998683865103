import {
  Directions,
  Orientations,
  type Direction,
  type Orientation,
} from "../constants"
import type { ElementCollectionStore } from "./collectionContext"
import type { EventHandler } from "./event"
import { nextKey, prevKey } from "./keyboard"

export type ElementCollectionListenersMap = Map<
  string | number,
  EventHandler<KeyboardEvent>
>

/**
 * Automatically add and remove appropriate keyboard event listeners
 * to elements within a collection that are navigated together
 * e.g. Tabs, ButtonGroup, Select
 */
export function enableGroupKeyboardNavigation(
  collection: ElementCollectionStore,
  listenersMap: ElementCollectionListenersMap,
  orientation: Orientation = Orientations.HORIZONTAL,
  dir: Direction = Directions.LTR,
  loop = true
) {
  const eventType = "keydown"
  collection.subscribe((elements) => {
    const enabledElements = elements.filter((el) => !el.dataset.disabled)

    // index might not be reliable
    elements.forEach((el) => {
      if (!el.id) {
        throw new TypeError(
          "enableGroupKeyboardNavigation requires all elements to have a unique id, consider using UICL generateId()"
        )
      }
      const previousListener = listenersMap.get(el.id)
      if (previousListener) {
        el.removeEventListener(eventType, previousListener)
      }

      const enabledIndex = enabledElements.indexOf(el)
      const listener = (e: KeyboardEvent) => {
        if (e.key === nextKey(orientation, dir)) {
          e.preventDefault()
          nextItem(enabledElements, enabledIndex, loop)?.focus()
        } else if (e.key === prevKey(orientation, dir)) {
          e.preventDefault()
          prevItem(enabledElements, enabledIndex, loop)?.focus()
        }
      }

      listenersMap.set(el.id, listener)
      el.addEventListener(eventType, listener)
    })
  })
}

export function nextItem<T>(
  items: T[],
  index: number,
  loop = true
): T | undefined {
  return index === items.length - 1
    ? loop
      ? items[0]
      : items[index]
    : items[index + 1]
}
export function prevItem<T>(
  items: T[],
  index: number,
  loop = true
): T | undefined {
  return index === 0
    ? loop
      ? items[items.length - 1]
      : items[index]
    : items[index - 1]
}
