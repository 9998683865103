import {
  derived,
  get,
  writable,
  type Readable,
  type Writable,
} from "svelte/store"
import { ObjectSchema, ValidationError, type AnyObject } from "yup"
import { util } from "./util"

const NO_ERROR = ""
const IS_TOUCHED = true

export type FormState<Inf = Record<string, any>> = {
  form: Writable<Inf>
  errors: Writable<Record<keyof Inf, string>>
  touched: Writable<Record<keyof Inf, boolean>>
  modified: Readable<Record<keyof Inf, boolean>>
  isValid: Readable<boolean>
  isSubmitting: Writable<boolean>
  isValidating: Writable<boolean>
  isModified: Readable<boolean>
  updateField: (field: string, value: any) => void
  updateValidateField: (field: string, value: any) => Promise<any>
  updateTouched: (field: string, value: any) => void
  validateField: (field: string) => Promise<any>
  validateForm: () => Promise<any>
  updateInitialValues: (newValues: Inf) => void
  handleReset: () => void
  resetErrorsAndTouched: () => void
  state: Readable<{
    form: Inf
    errors: Record<keyof Inf, string>
    touched: Record<keyof Inf, boolean>
    modified: Record<keyof Inf, boolean>
    isValid: boolean
    isSubmitting: boolean
    isValidating: boolean
    isModified: boolean
  }>
  handleChange: (event: Event) => any
  handleSubmit: (event?: Event) => any
}

function isCheckbox(element: HTMLElement) {
  return element.getAttribute && element.getAttribute("type") === "checkbox"
}

function isFileInput(element: HTMLElement) {
  return element.getAttribute && element.getAttribute("type") === "file"
}

function resolveValue(element: HTMLInputElement) {
  if (isFileInput(element)) {
    return element.files
  } else if (isCheckbox(element)) {
    return element.checked
  } else {
    return element.value
  }
}

export const createForm = <
  Inf extends AnyObject = Record<string, any>
>(config: {
  initialValues: Inf
  onSubmit: (
    values: Inf,
    form: Writable<Inf>,
    errors: Writable<Record<keyof Inf, string>>
  ) => any | Promise<any>
  validate?: (values: Inf) => any | undefined
  validationSchema?: ObjectSchema<any>
}): FormState<Inf> => {
  let initialValues = config.initialValues || ({} as Inf)

  const validationSchema = config.validationSchema
  const validateFunction = config.validate
  const onSubmit = config.onSubmit

  const getInitial = {
    values: () => util.cloneDeep(initialValues),
    errors: () =>
      validationSchema
        ? util.getErrorsFromSchema(initialValues, validationSchema.fields)
        : util.assignDeep(initialValues, NO_ERROR),
    touched: () => util.assignDeep(initialValues, !IS_TOUCHED),
  }

  const form = writable<Inf>(getInitial.values())
  const errors = writable<Record<keyof Inf, string>>(
    getInitial.errors() as Record<keyof Inf, string>
  )
  const touched = writable<Record<keyof Inf, boolean>>(
    getInitial.touched() as Record<keyof Inf, boolean>
  )

  const isSubmitting = writable<boolean>(false)
  const isValidating = writable<boolean>(false)

  const isValid = derived(errors, ($errors) => {
    const noErrors = util
      .getValues($errors)
      .every((field) => field === NO_ERROR)
    return noErrors
  })

  const modified = derived(form, ($form) => {
    const object = util.assignDeep($form, false)

    for (const key in $form) {
      object[key] = !util.deepEqual($form[key], initialValues[key as keyof Inf])
    }

    return object
  })

  const isModified = derived(modified, ($modified) => {
    return util.getValues($modified).includes(true)
  })

  function validateField(field: string) {
    return util
      .subscribeOnce(form)
      .then((values) => validateFieldValue(field, values[field]))
  }

  function validateFieldValue(field: string, value: any) {
    updateTouched(field, true)

    if (validationSchema) {
      isValidating.set(true)

      const $form = get(form)

      return validationSchema
        .validateAt(field, $form, { context: $form })
        .then(() => util.update(errors, field, ""))
        .catch((error) => util.update(errors, field, error.message))
        .finally(() => {
          isValidating.set(false)
        })
    }

    if (validateFunction) {
      isValidating.set(true)
      return Promise.resolve()
        .then(() => validateFunction({ [field]: value } as unknown as Inf))
        .then((errs) =>
          util.update(errors, field, !util.isNullish(errs) ? errs[field] : "")
        )
        .finally(() => {
          isValidating.set(false)
        })
    }

    return Promise.resolve()
  }

  function updateValidateField(field: string, value: any) {
    updateField(field, value)
    return validateFieldValue(field, value)
  }

  function handleChange(event: Event) {
    const element = event.target as HTMLInputElement
    const field = element.name || element.id
    const value = resolveValue(element)

    return updateValidateField(field, value)
  }

  function validateValues(values: Inf) {
    if (typeof validateFunction === "function") {
      isValidating.set(true)

      return Promise.resolve()
        .then(() => validateFunction(values))
        .then((error) => {
          errors.set(error)

          return error
        })
        .finally(() => isValidating.set(false))
    } else if (validationSchema) {
      isValidating.set(true)

      return validationSchema
        .validate(values, { abortEarly: false, context: values })
        .then(() => {
          return {}
        }) // NOTE:[KT] return empty errors object if validation does not raise an error
        .catch((error) => {
          if (ValidationError.isError(error) && error.inner) {
            const updatedErrors = getInitial.errors()

            error.inner.map((error) =>
              util.set(updatedErrors, error.path!, error.message)
            )

            errors.set(updatedErrors as Record<keyof Inf, string>)

            return updatedErrors
          } else {
            throw error
          }
        })
        .finally(() => isValidating.set(false))
    } else {
      // NOTE: [KT] no validation defined so return a promise to conform to the return type
      return Promise.resolve()
    }
  }

  function validateForm() {
    return util.subscribeOnce(form).then((values) => {
      return validateValues(values)
    })
  }

  function handleSubmit(event?: Event) {
    if (event && event.preventDefault) {
      event.preventDefault()
    }

    isSubmitting.set(true)

    return util.subscribeOnce(form).then((values) => {
      return validateValues(values)
        .then((error) => {
          if (util.isNullish(error) || util.getValues(error).length === 0) {
            return clearErrorsAndSubmit(values)
          }
        })
        .finally(() => {
          isSubmitting.set(false)
        })
    })
  }

  function handleReset() {
    form.set(getInitial.values())
    errors.set(getInitial.errors() as Record<keyof Inf, string>)
    touched.set(getInitial.touched() as Record<keyof Inf, boolean>)
  }

  function resetErrorsAndTouched() {
    errors.set(getInitial.errors() as Record<keyof Inf, string>)
    touched.set(getInitial.touched() as Record<keyof Inf, boolean>)
  }

  function clearErrorsAndSubmit(values: Inf) {
    return Promise.resolve()
      .then(() => errors.set(getInitial.errors() as Record<keyof Inf, string>))
      .then(() => onSubmit(values, form, errors))
      .finally(() => isSubmitting.set(false))
  }

  /**
   * Handler to imperatively update the value of a form field
   */
  function updateField(field: string, value: any) {
    util.update(form, field, value)
  }

  /**
   * Handler to imperatively update the touched value of a form field
   */
  function updateTouched(field: string, value: any) {
    util.update(touched, field, value)
  }

  /**
   * Update the initial values and reset form. Used to dynamically display new form values
   */
  function updateInitialValues(newValues: Inf) {
    initialValues = newValues

    handleReset()
  }

  return {
    form,
    errors,
    touched,
    modified,
    isValid,
    isSubmitting,
    isValidating,
    isModified,
    handleChange,
    handleSubmit,
    handleReset,
    resetErrorsAndTouched,
    updateField,
    updateValidateField,
    updateTouched,
    validateField,
    validateForm,
    updateInitialValues,
    state: derived(
      [
        form,
        errors,
        touched,
        modified,
        isValid,
        isValidating,
        isSubmitting,
        isModified,
      ],
      ([
        $form,
        $errors,
        $touched,
        $modified,
        $isValid,
        $isValidating,
        $isSubmitting,
        $isModified,
      ]) => ({
        form: $form,
        errors: $errors,
        touched: $touched,
        modified: $modified,
        isValid: $isValid,
        isSubmitting: $isSubmitting,
        isValidating: $isValidating,
        isModified: $isModified,
      })
    ),
  }
}
